@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: popular-offer-list;

$gap: 16px;

.popular-offer-list__item {
  min-width: 216px;
  max-height: 270px;

  @media (min-width: $tablet-portrait) {
    min-width: 312px;
    max-height: 388px;
  }

  @media (min-width: $tablet-landscape) {
    width: 100%;
    min-width: calc(33.3% - #{$gap});
    max-height: 468px;
  }

  &:not(:last-child) {
    margin-right: 12px;

    @media (min-width: $tablet-portrait) {
      margin-right: $gap;
    }
  }
}
