@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: subscription-result-message;

.subscription-result-message_theme_standard {
  &::before {
    content: '';
    display: block;
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
    background-size: contain;
  }

  &.subscription-result-message_type_error {
    &::before {
      background-image: url('./subscription-result-message_theme_standard__icon--error.svg');
    }
  }

  &.subscription-result-message_type_warning {
    &::before {
      background-image: url('./subscription-result-message_theme_standard__icon--warning.svg');
    }
  }

  &.subscription-result-message_type_success {
    &::before {
      background-image: url('./subscription-result-message_theme_standard__icon--success.svg');
    }
  }
}
