@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-categories-list;

.offer-categories-list__section {
  $anchor-top-margin: 20px;

  margin-top: -$anchor-top-margin;
  border-top: $anchor-top-margin solid transparent;

  &:not(:last-child) {
    margin-bottom: 40px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 48px;
    }

    @media (min-width: $desktop) {
      margin-bottom: 72px;
    }
  }
}
