@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: already-subscribed-message;

.already-subscribed-message__text {
  max-width: 288px;
  margin: 0 auto;
  text-align: center;
  letter-spacing: .5px;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;

  @media (min-width: $tablet-portrait) {
    max-width: 648px;
    font-size: 32px;
    line-height: 40px;
  }
}
