@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: motion-picture-carousel;

.motion-picture-carousel__items {
  display: flex;
  align-items: flex-start;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  margin: 0 #{-$page-outside-margin--smartphone};
  padding: 0 #{$page-outside-margin--smartphone};
  transition-property: transform, height;

  @media (min-width: $tablet-portrait) {
    margin-right: #{-$page-outside-margin--tablet-portrait};
    margin-left: #{-$page-outside-margin--tablet-portrait};
    padding-right: $page-outside-margin--tablet-portrait;
    padding-left: $page-outside-margin--tablet-portrait;
  }

  @media (min-width: $tablet-landscape) {
    margin-right: #{-$page-outside-margin--tablet-landscape};
    margin-left: #{-$page-outside-margin--tablet-landscape};
    padding-right: $page-outside-margin--tablet-landscape;
    padding-left: $page-outside-margin--tablet-landscape;
  }

  @media (min-width: $desktop) {
    margin: 0;
    padding: 0;
  }
}
