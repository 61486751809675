@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: gazprom-bonus-benefit-list;

.gazprom-bonus-benefit-list {
  $columns: 2;
  $gap: 12px;
  $max-item-width: 204px;

  display: grid;
  grid-template-columns: repeat($columns * 2, min(calc((50% - $gap) * .5), ($max-item-width - $gap) * .5));
  gap: $gap;
  justify-content: center;
  margin: 0;
  padding: 0;

  @media (min-width: $tablet-portrait) {
    $columns: 3;
    $gap: 32px;
    $item-width: 204px;

    grid-template-columns: repeat($columns * 2, ($item-width - $gap) * .5);
    gap: $gap;
  }

  @media (min-width: $tablet-landscape) {
    grid-template-columns: repeat(5, 168px);
    gap: 16px;
  }

  @media (min-width: $desktop) {
    grid-template-columns: repeat(5, 204px);
    gap: 12px;
  }
}
