@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: megafon-tv-plus-kinopoisk-logo;

.megafon-tv-plus-kinopoisk-logo {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 60px;
  margin: 0;
  padding: 16px;
  text-decoration: none;
  font: inherit;
  color: $color--body;
  user-select: none;

  &:focus {
    outline-width: 1px;
    outline-offset: -8px;
    -webkit-tap-highlight-color: rgba($background-color--button-pressed, .05);

    @media (min-width: $tablet-portrait) {
      outline-offset: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }
}
