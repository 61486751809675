@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: popular-offer-list;

.popular-offer-list__title {
  margin: 0 0 16px;
  font-size: 20px;
  line-height: 28px;
  background: linear-gradient(90deg, #bd25d6 0%, #731982 10%);
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-background-clip: text;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-text-fill-color: transparent;

  @media (min-width: $desktop) {
    margin-bottom: 24px;
  }
}
