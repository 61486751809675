@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-category-card;

.offer-category-card__link {
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: inherit;
  transition: color .1s;

  @media (min-width: $tablet-portrait) {
    font-size: 15px;
    line-height: 18px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    inset: 0;
    border-radius: 12px;
  }

  &:focus {
    outline: none;
  }
}
