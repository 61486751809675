@mixin promo-banner-background-image($handle) {
  $retina-resolution: 144dpi;

  background-position: center;
  background-repeat: no-repeat;

  // stylelint-disable plugin/selector-bem-pattern

  .no-webpalpha & {
    background-image: url('./_offer/_#{$handle}/promo-banner_offer_#{$handle}--mobile.png');

    @media (min-resolution: $retina-resolution) {
      background-image: url('./_offer/_#{$handle}/promo-banner_offer_#{$handle}--mobile@2x.png');
    }
  }

  .webpalpha & {
    background-image: url('./_offer/_#{$handle}/promo-banner_offer_#{$handle}--mobile.webp');

    @media (min-resolution: $retina-resolution) {
      background-image: url('./_offer/_#{$handle}/promo-banner_offer_#{$handle}--mobile@2x.webp');
    }
  }

  @media (min-width: $desktop) {
    .no-webpalpha & {
      background-image: url('./_offer/_#{$handle}/promo-banner_offer_#{$handle}--desktop.png');

      @media (min-resolution: $retina-resolution) {
        background-image: url('./_offer/_#{$handle}/promo-banner_offer_#{$handle}--desktop@2x.png');
      }
    }

    .webpalpha & {
      background-image: url('./_offer/_#{$handle}/promo-banner_offer_#{$handle}--desktop.webp');

      @media (min-resolution: $retina-resolution) {
        background-image: url('./_offer/_#{$handle}/promo-banner_offer_#{$handle}--desktop@2x.webp');
      }
    }
  }
}
