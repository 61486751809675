@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: zvuk-app-banner;

$action-width: 135px;
$action-gap: 20px;

.zvuk-app-banner__actions {
  display: grid;
  grid-template-columns: repeat(auto-fill, $action-width);
  gap: $action-gap;
  justify-content: center;
  max-width: $action-width * 2 + $action-gap;
  margin: 0 auto;

  @media (min-width: $tablet-portrait) {
    justify-content: flex-start;
    max-width: auto;
    margin: 0;
  }
}
