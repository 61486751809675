@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: popular-offer-card;

@import 'popular-offer-card.vars';

.popular-offer-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  &:hover,
  &:focus-within {
    box-shadow: 0 5px $box-shadow-size 0 rgba($black, .15);
  }
}
