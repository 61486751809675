@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: gazprom-bonus-benefit-card;

$themes: (
  'black': #515151 $color--inverted-body,
  'blue': #2b9bd0 $color--inverted-body,
  'yellow': #f7bf2b $color--body,
  'pink': #f33882 $color--inverted-body,
  'orange': #ff7342 $color--inverted-body,
);

@each $name, $colors in $themes {
  .gazprom-bonus-benefit-card_theme_#{$name} {
    // stylelint-disable-next-line plugin/selector-bem-pattern
    .gazprom-bonus-benefit-card__description {
      background-color: nth($colors, 1);
      color: nth($colors, 2);
    }
  }
}
