@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: megafon-tv-plus-kinopoisk-logo;

@import '../megafon-tv-plus-kinopoisk-logo.vars';

.megafon-tv-plus-kinopoisk-logo__service {
  display: inline-flex;
  position: relative;
  max-width: 130px;

  @media (min-width: $tablet-portrait) {
    max-width: none;
  }
}
