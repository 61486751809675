@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: kinopoisk-feature-list;

.kinopoisk-feature-list__item {
  display: block;
  order: var(--mobile-order);
  max-width: 438px;
  break-inside: avoid;

  @media (min-width: $tablet-landscape) {
    max-width: none;
  }

  &:first-child {
    @media (min-width: $tablet-landscape) {
      margin-top: 156px;
    }
  }

  &:not(:first-child) {
    margin-top: 48px;

    @media (min-width: $tablet-portrait) {
      margin-top: 60px;
    }

    @media (min-width: $tablet-landscape) {
      margin-top: 120px;
    }
  }
}
