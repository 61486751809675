@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-category-card;

.offer-category-card_type_video {
  &::before {
    box-shadow: 0 4px 9px 0 rgba(232, 83, 4, .35);
    background-image: url('./offer-category-card_type_video.svg'), linear-gradient(to bottom, #ff9175, #e85304);
  }

  &:hover,
  &:focus-within {
    .offer-category-card__link {
      color: #e85304;
    }
  }
}
