@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: subscription-confirmation-message;

.subscription-confirmation-message__price {
  margin: 0;
  font-size: 12px;
  line-height: 16px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .subscription-confirmation-message__highlighted-text {
    display: block;
    margin-top: 4px;

    @media (min-width: $tablet-portrait) {
      display: inline;
      margin-top: 0;
    }
  }
}
