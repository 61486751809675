@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: megafon-tv-plus-kinopoisk-logo;

@import '../megafon-tv-plus-kinopoisk-logo.vars';

.megafon-tv-plus-kinopoisk-logo__logo {
  $delimeter-width: 2px;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  width: auto;
  max-width: 128px;
  height: auto;
  max-height: $logo-height;
  margin-right: 10px;
  padding-right: 10px + $delimeter-width;

  @media (min-width: $tablet-portrait) {
    max-width: 156px;
    margin-right: 20px;
    padding-right: 24px + $delimeter-width;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: $logo-height;
    border-right: $delimeter-width solid $color--brand;
  }
}
