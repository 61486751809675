@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

// stylelint-disable plugin/selector-bem-pattern

$retina-resolution: 144dpi;

@mixin background-image($breakpoint) {
  .no-webpalpha & {
    background-image: url('./promo-banner_offer_kaspersky-standard--#{$breakpoint}.jpg');

    @media (min-resolution: $retina-resolution) {
      background-image: url('./promo-banner_offer_kaspersky-standard--#{$breakpoint}@2x.jpg');
    }
  }

  .webpalpha & {
    background-image: url('./promo-banner_offer_kaspersky-standard--#{$breakpoint}.webp');

    @media (min-resolution: $retina-resolution) {
      background-image: url('./promo-banner_offer_kaspersky-standard--#{$breakpoint}@2x.webp');
    }
  }
}

.promo-banner_offer_kaspersky-standard {
  position: relative;
  background-color: #030919;

  .promo-banner__content {
    position: relative;
    padding-top: 64px;
    padding-bottom: 55%;

    @media (min-width: $tablet-portrait) {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    @media (min-width: $tablet-landscape) {
      padding-top: 48px;
      padding-bottom: 40px;
    }

    @media (min-width: $desktop) {
      padding-top: 54px;
      padding-bottom: 54px;
    }
  }

  .promo-banner__main {
    &::before {
      content: none;
    }
  }

  .promo-banner__content-canvas {
    max-width: 280px;
    margin: 0 auto;

    @media (min-width: $tablet-portrait) {
      max-width: 360px;
      margin-left: 0;
      padding: 0;
    }

    @media (min-width: $tablet-landscape) {
      max-width: 480px;
    }
  }

  .promo-banner__background {
    @each $breakpoint in (
      'mobile' null,
      'tablet-portrait' $tablet-portrait ,
      'tablet-landscape' $tablet-landscape,
      'desktop' $desktop,
    ) {
      @if (nth($breakpoint, 2)) {
        @media (min-width: nth($breakpoint, 2)) { // stylelint-disable-line scss/media-feature-value-dollar-variable
          @include background-image(nth($breakpoint, 1));
        }
      } @else {
        @include background-image(nth($breakpoint, 1));
      }
    }

    @media (min-width: $tablet-portrait) {
      background-position: right;
      background-size: auto 100%;
    }

    position: absolute;
    min-height: auto;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    inset: 0;

    &::before {
      content: none;
    }
  }

  .promo-banner__actions {
    margin-top: 16px;
  }

  .promo-banner__action {
    display: block;

    @media (min-width: $tablet-portrait) {
      text-align: left;
    }
  }

  .promo-banner__action-note {
    margin-top: 16px;
    font-size: $font-size--body;
    line-height: $line-height--body;
  }
}
