@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

@import '../../promo-banner-background-image.mixin';

.promo-banner_offer_zvuk {
  &.promo-banner_type_landing {
    .promo-banner__content-canvas {
      @media (min-width: $tablet-portrait) {
        max-width: 42%;
      }
    }

    .promo-banner__background {
      padding-top: 16px;

      @media (min-width: $tablet-portrait) {
        padding-top: 0;
      }

      &::before {
        @include promo-banner-background-image('zvuk');

        box-sizing: border-box;
        margin-right: -$page-outside-margin--smartphone - 24px;
        margin-left: -$page-outside-margin--smartphone - 24px;

        @media (min-width: $tablet-portrait) {
          max-width: 528px;
          margin-left: auto;
          background-position: bottom right;
          background-size: contain;
        }

        @media (min-width: $tablet-landscape) {
          max-width: 670px;
        }

        @media (min-width: $desktop) {
          max-width: 720px;
        }
      }
    }
  }
}
