@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

.promo-banner__highlighted-note {
  display: block;
  transform: rotate(-6deg);
  box-sizing: border-box;
  width: 104px;
  margin: 12px auto 0;
  padding: 8px;
  text-align: center;
  text-transform: lowercase;
  font-size: 15px;
  line-height: 20px;
  background: #e44232;

  @media (min-width: $tablet-portrait) {
    display: inline-block;
    position: absolute;
    margin: 0 0 0 16px;
  }
}
