@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-list;

$gap-mobile: 12px;
$gap-tablet-portrait: 24px;

.offer-list__item {
  flex-shrink: 0;
  width: 252px;

  @media (min-width: $tablet-portrait) {
    flex-grow: 1;
    width: calc((100% - #{$gap-tablet-portrait} * 3) / 4);
    min-width: 252px;
    max-width: 404px;
  }

  &:not(:last-child) {
    margin-right: $gap-mobile;
    margin-bottom: 16px;

    @media (min-width: $tablet-portrait) {
      margin-right: $gap-tablet-portrait;
      margin-bottom: 0;
    }
  }
}
