@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: motion-picture-card;

.motion-picture-card__age-restriction-badge {
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba($black, .15);
  background-color: rgba($white, .8);
  color: $color--secondary-text;
}
