@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: main-promo-banner;

$retina-resolution: 144dpi;

.main-promo-banner__layout {
  display: flex;
  position: relative;
  box-sizing: border-box;
  min-height: 146px;
  padding: 20px 120px 32px $page-outside-margin--smartphone;
  background: $background-color--brand url('../main-promo-banner--mobile.png') no-repeat 100% 16px / contain;
  color: $color--inverted-body;

  @media (min-resolution: $retina-resolution) {
    background-image: url('../main-promo-banner--mobile@2x.png');
  }

  @media (min-width: $tablet-portrait) {
    min-height: 216px;
    padding-right: 48px;
    padding-left: 48px;
    background-image: url('../main-promo-banner--desktop.png');
    background-position: top 8px right 48px;

    @media (min-resolution: $retina-resolution) {
      background-image: url('../main-promo-banner--desktop@2x.png');
    }
  }

  @media (min-width: $tablet-landscape) {
    border-radius: 12px;
  }
}
