@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: kinopoisk-feature-card;

.kinopoisk-feature-card__icon-badge {
  position: absolute;
  top: 42px;
  width: 148px;
  height: 56px;
  border-radius: 28px;
  box-shadow: inset -2px -2px 3px 0 rgba($black, .2);
  background-image: url('./kinopoisk-feature-card__icon-badge.svg'), linear-gradient(45deg, #ff5c00 3.51%, #ea507c 20.3%, #b941ef 38.1%, #5b61ff 63.54%, #21bee7 92.54%);
  background-position: 10px center, 0% 0%;
  background-size: auto 70%, auto;
  background-repeat: no-repeat;

  @media (min-width: $tablet-portrait) {
    top: 60px;
    width: 202px;
    height: 74px;
    border-radius: 37px;
  }

  @media (min-width: $desktop) {
    top: 64px;
    width: 238px;
    height: 90px;
    border-radius: 45px;
  }

  &::after {
    content: '';
    position: absolute;
    margin: 2px;
    padding: 1px;
    border-radius: 50px;
    background: linear-gradient(2deg, rgba($white, .01) 92%, rgba($white, .9));
    inset: 0;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
