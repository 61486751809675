@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-article;

.offer-article__image-canvas {
  position: relative;
  overflow: hidden;
  padding-bottom: 65%;
  background: $gray;
  clip-path: circle(140% at 50% -83%);

  &::before {
    @media (min-width: $tablet-portrait) {
      content: '';
      position: absolute;
      z-index: 1;
      inset: 0;
      background-image: linear-gradient(to top, rgba($gray, .25) 0%, rgba($gray, 0) 50%);
      pointer-events: none;
    }
  }

  @media (min-width: $tablet-portrait) {
    padding-bottom: 30%;
    clip-path: none;
  }
}
