@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: raw-content;

// stylelint-disable funbox-stylelint-rules/selector-max-type, no-descending-specificity, plugin/selector-bem-pattern

.raw-content_theme_context {
  a {
    text-decoration: underline;
    color: inherit;

    &:hover,
    &:focus {
      outline: 0;
      color: $color--link;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &:active {
      color: $green-swamp;
    }
  }
}
