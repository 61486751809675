@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: gazprom-bonus-benefit-list;

.gazprom-bonus-benefit-list__item {
  display: block;
  grid-column: span 2;

  &:nth-last-child(1) {
    grid-column: 2 / span 2;
  }

  @media (min-width: $tablet-portrait) {
    &:nth-last-child(1) {
      grid-column: span 2;
    }

    &:nth-last-child(-n + 2):nth-child(even) {
      grid-column: 2 / span 2;
    }
  }

  @media (min-width: $tablet-landscape) {
    &,
    &:nth-last-child(1),
    &:nth-last-child(-n + 2):nth-child(even) {
      grid-column: auto;
    }
  }
}
