@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: kinopoisk-feature-card;

/* stylelint-disable plugin/selector-bem-pattern */

$retina-resolution: 144dpi;

.kinopoisk-feature-card__icon {
  position: relative;
  box-sizing: border-box;
  width: 242px;
  height: 242px;
  margin: 0 auto 32px;
  padding: 46px 42px 0;
  text-align: center;
  background: radial-gradient(50% 50% at center, $green, $green calc(100% - 1px), $white 100%);

  @media (min-width: $tablet-portrait) {
    width: 328px;
    height: 328px;
    margin: 0 auto 40px;
    padding: 54px 64px 0;
  }

  @media (min-width: $tablet-landscape) {
    margin: 0 0 40px;
  }

  @media (min-width: $desktop) {
    width: 398px;
    height: 398px;
    margin-bottom: 44px;
    padding: 72px 88px 0;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;

    .no-webpalpha & {
      background-image: var(--image-mobile-png-1x);

      @media (min-resolution: $retina-resolution) {
        background-image: var(--image-mobile-png-2x);
      }
    }

    .webpalpha & {
      background-image: var(--image-mobile-webp-1x);

      @media (min-resolution: $retina-resolution) {
        background-image: var(--image-mobile-webp-2x);
      }
    }

    @media (min-width: $tablet-portrait) {
      height: 180px;

      .no-webpalpha & {
        background-image: var(--image-tablet-portrait-png-1x);

        @media (min-resolution: $retina-resolution) {
          background-image: var(--image-tablet-portrait-png-2x);
        }
      }

      .webpalpha & {
        background-image: var(--image-tablet-portrait-webp-1x);

        @media (min-resolution: $retina-resolution) {
          background-image: var(--image-tablet-portrait-webp-2x);
        }
      }
    }

    @media (min-width: $desktop) {
      height: 220px;

      .no-webpalpha & {
        background-image: var(--image-desktop-png-1x);

        @media (min-resolution: $retina-resolution) {
          background-image: var(--image-desktop-png-2x);
        }
      }

      .webpalpha & {
        background-image: var(--image-desktop-webp-1x);

        @media (min-resolution: $retina-resolution) {
          background-image: var(--image-desktop-webp-2x);
        }
      }
    }
  }

  &::after {
    content: var(--icon-text);
    letter-spacing: .5px;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: $white;

    @media (min-width: $tablet-portrait) {
      font-size: 28px;
      line-height: 36px;
    }

    @media (min-width: $desktop) {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
