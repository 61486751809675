@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-article;

.offer-article_selling-type_one-subscription {
  .offer-article__content {
    @media (min-width: $tablet-portrait) {
      grid-template-columns: 1fr 240px;
      column-gap: 36px;
    }
  }

  .offer-article__title {
    margin-bottom: 8px;

    @media (min-width: $tablet-portrait) {
      grid-column: span 3;
      margin-bottom: 56px;
    }
  }

  .offer-article__summary {
    margin-bottom: 12px;

    @media (min-width: $tablet-portrait) {
      grid-row: 2;
      grid-column-start: 1;
    }
  }

  .offer-article__description {
    @media (min-width: $tablet-portrait) {
      grid-row: 3;
      grid-column-start: 1;
    }
  }

  .offer-article__price {
    margin-bottom: 24px;

    @media (min-width: $tablet-portrait) {
      display: none;
    }
  }

  .offer-article__action {
    @media (min-width: $tablet-portrait) {
      display: none;
    }
  }

  .offer-article__subscription-card {
    display: none;

    @media (min-width: $tablet-portrait) {
      display: block;
      grid-column: 2 / 4;
      grid-row: 2 / span 3;
      justify-self: end;
      min-width: 270px;
      max-width: 280px;
    }
  }
}
