@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

.promo-banner__main {
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-portrait) {
    display: block;
  }

  &::before {
    @media (min-width: $tablet-portrait) {
      content: '';
      display: inline-block;
      min-height: 376px;
      vertical-align: middle;
    }

    @media (min-width: $tablet-landscape) {
      min-height: 416px;
    }
  }
}
