@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: step-list;

$gap--tablet-landscape: 24px;
$gap--desktop: 36px;
$counter-size: 40px;
$counter-margin--tablet-landscape: 18px;
$counter-margin--desktop: 32px;
$line-height: 2px;

@mixin line-width($counter-margin, $gap) {
  width: calc((100% - #{$counter-margin} * 2 - #{$counter-size}) + #{$gap});
}

.step-list_theme_flexy {
  @media (min-width: $tablet-landscape) {
    display: flex;
  }

  .step-list__step {
    display: flex;
    align-items: flex-start;

    @media (min-width: $tablet-landscape) {
      flex: 1 1;
      align-items: center;
      flex-direction: column;
      position: relative;
      text-align: center;
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @media (min-width: $tablet-landscape) {
        margin: 0 $gap--tablet-landscape 0 0;
      }

      @media (min-width: $desktop) {
        margin-right: $gap--desktop;
      }
    }

    &::before {
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 36px;
      height: 36px;
      margin-right: 16px;
      font-weight: 500;
      border: 2px $color--brand solid;
      border-radius: 18px;
      color: $color--brand;

      @media (min-width: $tablet-portrait) {
        margin-right: 20px;
      }

      @media (min-width: $tablet-landscape) {
        width: $counter-size;
        height: $counter-size;
        margin: 0 0 20px;
        border-radius: $counter-size * .5;
      }

      @media (min-width: $desktop) {
        margin-bottom: 24px;
      }
    }

    &:not(:last-child)::after {
      @media (min-width: $tablet-landscape) {
        content: '';
        display: block;
        position: absolute;
        top: $counter-size * .5 - $line-height * .5;
        right: -$gap--tablet-landscape * .5;
        transform: translateX(50%);
        height: $line-height;
        background-image: url('./step-list_theme_flexy.svg');
        background-repeat: space;

        @include line-width($counter-margin--tablet-landscape, $gap--tablet-landscape);
      }

      @media (min-width: $desktop) {
        right: -$gap--desktop * .5;

        @include line-width($counter-margin--desktop, $gap--desktop);
      }
    }
  }
}
