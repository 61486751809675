@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: gazprom-bonus-promo-banner;

$retina-resolution: 144dpi;

@mixin get-background-image($breakpoint: 'mobile', $secondary-background: none) {
  // stylelint-disable-next-line plugin/selector-bem-pattern
  .no-webpalpha & {
    background-image: url('./gazprom-bonus-promo-banner--#{$breakpoint}.jpg');

    @media (min-resolution: $retina-resolution) {
      background: url('./gazprom-bonus-promo-banner--#{$breakpoint}@2x.jpg');
    }
  }

  // stylelint-disable-next-line plugin/selector-bem-pattern
  .webpalpha & {
    background-image: url('./gazprom-bonus-promo-banner--#{$breakpoint}.webp'), $secondary-background;

    @media (min-resolution: $retina-resolution) {
      background-image: url('./gazprom-bonus-promo-banner--#{$breakpoint}@2x.webp'), $secondary-background;
    }
  }
}

.gazprom-bonus-promo-banner {
  background-color: #0074ba;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;

  @include get-background-image;

  @media (min-width: $tablet-portrait) {
    background-position: right;
    background-size: contain;

    @include get-background-image('tablet-portrait', linear-gradient(to bottom, #0196da, #017ec1));
  }

  @media (min-width: $tablet-landscape) {
    @include get-background-image('tablet-landscape', linear-gradient(to bottom, #0099dc, #007fc4));
  }

  @media (min-width: $desktop) {
    @include get-background-image('desktop', linear-gradient(to bottom, #0193d7, #017ec2));
  }
}
