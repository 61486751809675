@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: advantages-list;

.advantages-list_theme_compact {
  .advantages-list__item {
    display: flex;
    align-items: flex-start;

    @media (min-width: $tablet-portrait) {
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
  }

  .advantages-list__item-icon-canvas {
    margin-right: 12px;

    @media (min-width: $tablet-portrait) {
      margin-right: 0;
    }
  }
}
