@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

.promo-banner__background {
  order: 1;
  min-height: 208px;

  @media (min-width: $tablet-portrait) {
    display: inline-block;
    width: 100%;
    min-height: 376px;
    margin-right: -100%;
    vertical-align: bottom;
  }

  @media (min-width: $tablet-landscape) {
    min-height: 416px;
  }

  &::before {
    content: '';
    display: block;
    min-height: inherit;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;

    @media (min-width: $tablet-portrait) {
      background-position: bottom right;
    }
  }
}
