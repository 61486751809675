@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: gazprom-bonus-promo-banner;

@function get-padding($height) {
  @return ($height - $line-height--body) * .5;
}

.gazprom-bonus-promo-banner__action {
  min-width: 236px;
  height: 48px;
  margin: 12px 0 0;
  padding: get-padding(48px) 32px;
  text-align: center;
  text-decoration: none;
  font: inherit;
  font-weight: 500;
  border: 0;
  border-radius: 24px;
  background: $white;
  color: $black;
  cursor: pointer;
  user-select: none;

  @media (min-width: $tablet-landscape) {
    height: 60px;
    padding-top: get-padding(60px);
    padding-bottom: get-padding(60px);
    border-radius: 30px;
  }

  @media (min-width: $desktop) {
    min-width: 254px;
  }

  &:hover, &:focus {
    background: $background-color--button-focus;
    color: $white;
  }
}
