@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-categories;

@import '../../offer-category-card/offer-category-card.vars';

.offer-categories__list {
  display: flex;
  list-style-type: none;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 #{-$page-outside-margin--smartphone} #{-$box-shadow-size};
  padding: 0 $page-outside-margin--smartphone $box-shadow-size;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  @media (min-width: $tablet-portrait) {
    margin: 0 #{-$page-outside-margin--tablet-portrait} #{-$box-shadow-size};
    padding: 0 $page-outside-margin--tablet-portrait $box-shadow-size;
  }

  @media (min-width: $tablet-landscape) {
    margin: 0 #{-$page-outside-margin--tablet-landscape} #{-$box-shadow-size};
    padding: 0 $page-outside-margin--tablet-landscape $box-shadow-size;
  }

  &::after {
    content: '';
    flex-shrink: 0;
    width: $page-outside-margin--smartphone;
    margin-right: -$page-outside-margin--smartphone;

    @media (min-width: $tablet-portrait) {
      width: $page-outside-margin--tablet-portrait;
      margin-right: -$page-outside-margin--tablet-portrait;
    }

    @media (min-width: $tablet-landscape) {
      width: $page-outside-margin--tablet-landscape;
      margin-right: -$page-outside-margin--tablet-landscape;
    }
  }
}
