@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

$retina-resolution: 144dpi;

.promo-banner_offer_kinopoisk {
  &.promo-banner_type_landing {
    .promo-banner__content-canvas {
      @media (min-width: $tablet-portrait) {
        max-width: 576px;
      }

      @media (min-width: $desktop) {
        max-width: 678px;
      }
    }

    .promo-banner__description {
      @media (min-width: $tablet-portrait) {
        max-width: 425px;
      }

      @media (min-width: $desktop) {
        max-width: 564px;
      }
    }

    .promo-banner__background {
      min-height: 330px;

      @media (min-width: $tablet-portrait) {
        min-height: 444px;
      }

      @media (min-width: $desktop) {
        min-height: 452px;
      }

      &::before {
        background-size: auto 248px;

        @media (min-width: $tablet-portrait) {
          background-position-y: center;
          background-size: auto 242px;
        }

        @media (min-width: $tablet-landscape) {
          background-position-y: center;
          background-size: auto 320px;
        }

        @media (min-width: $desktop) {
          background-size: auto 360px;
        }

        // stylelint-disable plugin/selector-bem-pattern

        .no-webpalpha & {
          background-image: url('./promo-banner_offer_kinopoisk--mobile.png');

          @media (min-resolution: $retina-resolution) {
            background-image: url('./promo-banner_offer_kinopoisk--mobile@2x.png');
          }
        }

        .webpalpha & {
          background-image: url('./promo-banner_offer_kinopoisk--mobile.webp');

          @media (min-resolution: $retina-resolution) {
            background-image: url('./promo-banner_offer_kinopoisk--mobile@2x.webp');
          }
        }

        @media (min-width: $tablet-portrait) {
          .no-webpalpha & {
            background-image: url('./promo-banner_offer_kinopoisk--tablet-portrait.png');

            @media (min-resolution: $retina-resolution) {
              background-image: url('./promo-banner_offer_kinopoisk--tablet-portrait@2x.png');
            }
          }

          .webpalpha & {
            background-image: url('./promo-banner_offer_kinopoisk--tablet-portrait.webp');

            @media (min-resolution: $retina-resolution) {
              background-image: url('./promo-banner_offer_kinopoisk--tablet-portrait@2x.webp');
            }
          }
        }

        @media (min-width: $desktop) {
          .no-webpalpha & {
            background-image: url('./promo-banner_offer_kinopoisk--desktop.png');

            @media (min-resolution: $retina-resolution) {
              background-image: url('./promo-banner_offer_kinopoisk--desktop@2x.png');
            }
          }

          .webpalpha & {
            background-image: url('./promo-banner_offer_kinopoisk--desktop.webp');

            @media (min-resolution: $retina-resolution) {
              background-image: url('./promo-banner_offer_kinopoisk--desktop@2x.webp');
            }
          }
        }
      }
    }
  }
}
