@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: dialog-window;

.dialog-window_type_subscription {
  &.dialog-window_theme_standard {
    padding: 16px 20px;

    @media (min-width: $tablet-portrait) {
      width: 600px;
      max-width: none;
      min-height: auto;
      padding: 32px;
    }

    @media (min-width: $tablet-landscape) {
      width: 800px;
    }

    @media (min-width: $desktop) {
      width: 928px;
    }

    .dialog-window__header {
      margin-bottom: 16px;
      text-align: left;

      @media (min-width: $tablet-portrait) {
        margin-bottom: 32px;
      }
    }

    .dialog-window__title {
      font-size: 15px;
      line-height: 21px;

      @media (min-width: $desktop) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
