@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: advantages-list;

.advantages-list_theme_stretched {
  .advantages-list__items {
    @media (min-width: $tablet-landscape) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .advantages-list__item {
    display: flex;
    place-items: center;
    flex-direction: column;

    @media (min-width: $tablet-landscape) {
      align-items: flex-start;
      flex-direction: row;
      width: fit-content;
    }
  }

  .advantages-list__item-icon-canvas {
    margin: 0 0 16px;

    @media (min-width: $tablet-landscape) {
      margin-right: 28px;
      margin-bottom: 0;
    }
  }

  .advantages-list__item-text {
    max-width: 238px;
    text-align: center;

    @media (min-width: $tablet-landscape) {
      text-align: left;
    }
  }
}
