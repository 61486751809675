@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: app-layout;

.app-layout__throbber {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation-name: app-layout__throbber;
  animation-duration: .3s;
  animation-delay: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes app-layout__throbber {
  100% {
    opacity: 1;
  }
}
