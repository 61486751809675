@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: melody-list;

@import '../melody-list.vars.scss';

.melody-list__item {
  display: block;
  flex-shrink: 0;
  position: relative;
  width: $item-width--smartphone;

  @media (min-width: $desktop) {
    width: 200px;
  }

  &:not(:last-child) {
    margin-right: $item-gap--smartphone;

    @media (min-width: $desktop) {
      margin-right: 24px;
    }
  }
}
