@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-article;

.offer-article__action {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px 20px;
  text-align: center;
  border-top: 1px solid $gray-spb-sky-1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: $background-color--body;

  @media (min-width: $tablet-portrait) {
    border-top: none;
  }
}
