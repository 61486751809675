@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-categories;

.offer-categories__item {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 120px;
  flex-direction: column;

  @media (min-width: $tablet-portrait) {
    flex-basis: 138px;
  }

  @media (min-width: $desktop) {
    flex-basis: 146px;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
}
