@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: game-list;

.game-list__items {
  margin: 0 #{-$page-outside-margin--smartphone};
  padding: 0 #{$page-outside-margin--smartphone};

  @media (min-width: $tablet-portrait) {
    margin-right: #{-$page-outside-margin--tablet-portrait};
    margin-left: #{-$page-outside-margin--tablet-portrait};
    padding-right: $page-outside-margin--tablet-portrait;
    padding-left: $page-outside-margin--tablet-portrait;
  }

  @media (min-width: $tablet-landscape) {
    margin-right: #{-$page-outside-margin--tablet-landscape};
    margin-left: #{-$page-outside-margin--tablet-landscape};
    padding-right: $page-outside-margin--tablet-landscape;
    padding-left: $page-outside-margin--tablet-landscape;
  }

  @media (min-width: $desktop) {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
