$b: popular-offer-card;

@use 'sass:math';
@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
@import '../popular-offer-card.vars';

.popular-offer-card__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left top;
  aspect-ratio: math.div($image-width, $image-height);

  @media (min-width: $tablet-portrait) {
    aspect-ratio: math.div($image-width--tablet-portrait, $image-height--tablet-portrait);
  }
}
