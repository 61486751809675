@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: motion-picture-carousel;

.motion-picture-carousel__item {
  display: flex;
  flex-shrink: 0;
  width: 280px;
  height: 530px;
  margin-right: $page-outside-margin--smartphone;

  @media (min-width: $tablet-portrait) {
    width: 312px;
    height: 564px;
    margin-right: $page-outside-margin--tablet-portrait;
  }

  @media (min-width: $desktop) {
    margin-right: 16px;
  }
}
