@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: gazprom-bonus-promo-banner;

.gazprom-bonus-promo-banner__title {
  margin: 0;
  letter-spacing: .5px;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;

  @media (min-width: $tablet-portrait) {
    font-size: 36px;
    line-height: 40px;
  }

  @media (min-width: $tablet-landscape) {
    font-size: 44px;
    line-height: 48px;
  }

  @media (min-width: $desktop) {
    font-size: 52px;
    line-height: 56px;
  }
}
