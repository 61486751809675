@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-article;

.offer-article_selling-type_link-to-service {
  .offer-article__content {
    @media (min-width: $tablet-portrait) {
      grid-row-gap: 24px;
    }
  }

  .offer-article__title {
    grid-column: 1 / 3;
    grid-row: 1;
    margin-bottom: 8px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 32px;
    }
  }

  .offer-article__summary {
    margin-bottom: 16px;

    @media (min-width: $tablet-portrait) {
      grid-column: 1;
      grid-row: 2;
      align-self: center;
      margin-bottom: 0;
    }
  }

  .offer-article__action {
    @media (min-width: $tablet-portrait) {
      grid-column: 2;
      grid-row: 2 / 2;
      position: static;
      padding: 0;
      text-align: right;
      background: transparent;
    }
  }

  .offer-article__action-control {
    @media (min-width: $tablet-portrait) {
      max-width: 220px;
    }
  }

  .offer-article__description {
    @media (min-width: $tablet-portrait) {
      grid-column: 1 / 3;
      grid-row: 3;
    }
  }
}
