@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: gazprom-bonus-benefit-card;

.gazprom-bonus-benefit-card__description {
  flex-grow: 1;
  align-self: stretch;
  box-sizing: border-box;
  margin: 0;
  padding: 50px 16px 28px;
  text-align: center;
  white-space: pre-line;
  font-weight: 500;
  border-radius: 32px;
  box-shadow: 0 0 16px 0 #d6d6d6;

  @media (min-width: $tablet-portrait) {
    padding: 58px 16px 36px;
    letter-spacing: .5px;
    font-size: 18px;
  }

  @media (min-width: $desktop) {
    padding: 58px 16px 24px;
    font-size: 20px;
    line-height: 28px;
  }
}
