@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: popular-offer-card-stub;

@import './popular-offer-card-stub.vars';

.popular-offer-card-stub {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: $background-color--secondary;

  &::before {
    content: '';
    display: block;
    aspect-ratio: $aspect-ratio;

    @media (min-width: $tablet-portrait) {
      aspect-ratio: $aspect-ratio--tablet-portrait;
    }

    @media (min-width: $desktop) {
      aspect-ratio: $aspect-ratio--desktop;
    }
  }
}
