@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: service-list;

$item-gap--smartphone: 60px;
$item-gap--tablet-portrait: 84px;

.service-list__item {
  display: block;
  position: relative;

  @media (min-width: $tablet-portrait) {
    display: flex;
    width: 468px;
  }

  &:not(:last-child) {
    margin: 0 0 $item-gap--smartphone;

    @media (min-width: $tablet-portrait) {
      margin: 0 $item-gap--tablet-portrait 0 0;
    }

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 50%;
      bottom: -$item-gap--smartphone * .5;
      transform: translate(50%);
      width: 24px;
      height: 6px;
      background-color: $black;

      @media (min-width: $tablet-portrait) {
        top: 50%;
        right: -$item-gap--tablet-portrait * .5;
        transform: translate(50%, -50%);
      }
    }

    &::after {
      transform: translate(50%) rotate(90deg);

      @media (min-width: $tablet-portrait) {
        transform: translate(50%, -50%) rotate(90deg);
      }
    }
  }
}
