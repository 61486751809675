@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

@import '../../promo-banner-background-image.mixin';

$retina-resolution: 144dpi;

.promo-banner_offer_lottery {
  &.promo-banner_type_landing {
    .promo-banner__background {
      box-sizing: border-box;
      padding-top: 24px;

      @media (min-width: $tablet-portrait) {
        padding-top: 0;
      }

      &::before {
        margin-right: -$page-outside-margin--smartphone - 24px;
        margin-bottom: -24px;
        margin-left: -$page-outside-margin--smartphone - 24px;
        background-image: url('./promo-banner_offer_lottery--mobile.png');
        background-position: bottom 24px left 50%;

        @media (min-resolution: $retina-resolution) {
          background-image: url('./promo-banner_offer_lottery--mobile@2x.png');
        }

        @media (min-width: $tablet-portrait) {
          background-image: url('./promo-banner_offer_lottery--desktop.png');

          @media (min-resolution: $retina-resolution) {
            background-image: url('./promo-banner_offer_lottery--desktop@2x.png');
          }
        }

        @media (min-width: $tablet-portrait) {
          transform: translateX(25%);
          max-width: 80%;
          margin: 0 0 0 auto;
          background-position: left bottom;
          background-size: contain;
        }

        @media (min-width: $desktop) {
          transform: translateX(84px);
          background-position: bottom right;
        }
      }
    }
  }
}
