@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-card;

@import 'offer-card.vars';

.offer-card {
  position: relative;
  text-align: left;
  border-radius: 8px;
  background-color: $gray-spb-sky-1;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 8px;
    transition: box-shadow .2s;
  }

  &:hover, &:focus-within {
    &::before {
      box-shadow: 0 5px $box-shadow-size 0 rgba(0, 0, 0, .15);
    }

    .offer-card__title {
      color: $background-color--brand;
    }

    .offer-card__summary {
      color: $color--body;
    }
  }
}
