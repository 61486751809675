@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: melody-list;

@import './melody-list.vars.scss';

.melody-list {
  display: flex;
  overflow-x: auto;
  margin: 0 #{-$page-outside-margin--smartphone};
  padding: 0 $page-outside-margin--smartphone;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  @media (min-width: $intermediate-breakpoint) {
    justify-content: center;
  }

  @media (min-width: $tablet-portrait) {
    margin: 0 #{-$page-outside-margin--tablet-portrait};
    padding: 0 $page-outside-margin--tablet-portrait;
  }

  @media (min-width: $tablet-landscape) {
    margin: 0 #{-$page-outside-margin--tablet-landscape};
    padding: 0 $page-outside-margin--tablet-landscape;
  }
}
