@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: zvuk-app-banner;

$retinaResolution: 144dpi;

.zvuk-app-banner {
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 32px;
  background-color: #f4f4f4;
  background-image: url('./zvuk-app-banner.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: $tablet-portrait) {
    display: flex;
    justify-content: stretch;
    min-height: 336px;
    background-position: left;
  }

  &::after {
    content: '';
    display: block;
    min-height: 212px;
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;

    @media (min-width: $tablet-portrait) {
      width: 380px;
    }

    // stylelint-disable plugin/selector-bem-pattern

    .no-webpalpha & {
      background-image: url('./zvuk-app-banner__image--mobile.png');

      @media (min-resolution: $retinaResolution) {
        background-image: url('./zvuk-app-banner__image--mobile@2x.png');
      }

      @media (min-width: $tablet-portrait) {
        background-image: url('./zvuk-app-banner__image--tablet-portrait.png');

        @media (min-resolution: $retinaResolution) {
          background-image: url('./zvuk-app-banner__image--tablet-portrait@2x.png');
        }
      }
    }

    .webpalpha & {
      background-image: url('./zvuk-app-banner__image--mobile.webp');

      @media (min-resolution: $retinaResolution) {
        background-image: url('./zvuk-app-banner__image--mobile@2x.webp');
      }

      @media (min-width: $tablet-portrait) {
        background-image: url('./zvuk-app-banner__image--tablet-portrait.webp');

        @media (min-resolution: $retinaResolution) {
          background-image: url('./zvuk-app-banner__image--tablet-portrait@2x.webp');
        }
      }
    }
  }
}
