@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: kinopoisk-feature-card;

.kinopoisk-feature-card__icon_crop_bottom {
  &::before {
    border-bottom-right-radius: 121px;
    border-bottom-left-radius: 121px;

    @media (min-width: $tablet-portrait) {
      border-bottom-right-radius: 164px;
      border-bottom-left-radius: 164px;
    }

    @media (min-width: $desktop) {
      border-bottom-right-radius: 199px;
      border-bottom-left-radius: 199px;
    }
  }
}
