@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-article;

.offer-article_selling-type_few-subscriptions {
  &.offer-article_subscriptions-displayed {
    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      inset: 0;
      background: rgba(0, 0, 0, 50%);

      @media (min-width: $tablet-portrait) {
        display: none;
      }
    }
  }

  .offer-article__content {
    @media (min-width: $tablet-portrait) {
      column-gap: 48px;
    }
  }

  .offer-article__title {
    margin-bottom: 8px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 56px;
    }
  }

  .offer-article__summary {
    margin-bottom: 12px;

    @media (min-width: $tablet-portrait) {
      grid-row: 2;
      grid-column-start: 1;
    }
  }

  .offer-article__description {
    @media (min-width: $tablet-portrait) {
      grid-row: 3;
      margin-bottom: 32px;
      grid-column-start: 1;
    }
  }

  .offer-article__action {
    @media (min-width: $tablet-portrait) {
      display: none;
    }
  }

  .offer-article__subscriptions {
    position: fixed;
    z-index: 3;
    inset: 30% 0 0;
    padding: 16px 20px;
    text-align: left;
    background: $background-color--body;

    @media (min-width: $tablet-portrait) {
      display: grid;
      position: static;
      padding: 0;
    }
  }

  .offer-article__subscriptions-list {
    overflow-y: scroll;
    box-sizing: border-box;
    max-height: 100%;
    padding-bottom: 64px;

    @media (min-width: $tablet-portrait) {
      box-sizing: content-box;
      max-height: none;
      padding-bottom: 0;
    }
  }

  .offer-article__subscriptions-close-button {
    margin-left: auto;

    @media (min-width: $tablet-portrait) {
      display: none;
    }
  }
}
