@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: zg-section-layout;

.zg-section-layout__action {
  max-width: 240px;
  margin: 12px auto 0;
}
