@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

@import '../../promo-banner-background-image.mixin';

.promo-banner_offer_yandex-plus {
  &.promo-banner_type_landing {
    .promo-banner__background {
      &::before {
        @include promo-banner-background-image('yandex-plus');

        background-size: 240px;

        @media (min-width: $tablet-portrait) {
          margin-left: auto;
          background-position: right center;
        }

        @media (min-width: $desktop) {
          background-size: 500px;
        }
      }
    }
  }
}
