@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: gazprom-bonus-promo-banner;

.gazprom-bonus-promo-banner__content-holder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 280px;
  margin: 0 auto;
  padding: 32px 20px 132px;
  text-align: center;

  @media (min-width: $tablet-portrait) {
    display: block;
    max-width: 360px;
    margin: 0;
    padding: 28px 0;
    text-align: left;
  }

  @media (min-width: $tablet-landscape) {
    max-width: 400px;
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @media (min-width: $desktop) {
    max-width: 480px;
  }
}
