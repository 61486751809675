@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

@import '../../promo-banner-background-image.mixin';

.promo-banner_offer_gfn {
  &.promo-banner_type_landing {
    .promo-banner__content-canvas {
      @media (min-width: $desktop) {
        max-width: 620px;
      }
    }

    .promo-banner__title {
      @media (min-width: $desktop) {
        font-size: 44px;
        line-height: 48px;
      }
    }

    .promo-banner__description {
      @media (min-width: $tablet-portrait) {
        margin-top: 56px;
      }
    }

    .promo-banner__actions {
      @media (min-width: $tablet-portrait) {
        margin-top: 56px;
      }
    }

    .promo-banner__background {
      @media (min-width: $tablet-portrait) {
        vertical-align: middle;
      }

      &::before {
        @include promo-banner-background-image('gfn');

        box-sizing: border-box;
        padding-bottom: 268px;

        @media (min-width: $tablet-portrait) {
          min-height: 316px;
          margin-top: 32px;
          padding-bottom: 0;
          background-position: bottom right;
          background-size: contain;
        }

        @media (min-width: $tablet-landscape) {
          min-height: 376px;
        }

        @media (min-width: $desktop) {
          background-position: bottom 0 right -48px;
        }
      }
    }
  }
}
