@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: page;

.page__section_type_landing {
  padding-top: 48px;
  padding-bottom: 48px;

  @media (min-width: $desktop) {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }

  &:not(.page__section_theme_darkened) + .page__section_type_landing:not(.page__section_theme_darkened) {
    padding-top: 0;
  }

  .page__section-content {
    box-sizing: border-box;
    max-width: $page-trim-width;
    margin: 0 auto;
    padding-right: $page-outside-margin--smartphone;
    padding-left: $page-outside-margin--smartphone;

    @media (min-width: $tablet-portrait) {
      padding-right: $page-outside-margin--tablet-portrait;
      padding-left: $page-outside-margin--tablet-portrait;
    }

    @media (min-width: $tablet-landscape) {
      padding-right: $page-outside-margin--tablet-landscape;
      padding-left: $page-outside-margin--tablet-landscape;
    }

    @media (min-width: $desktop) {
      overflow: visible;
      padding-right: $page-outside-margin--desktop;
      padding-left: $page-outside-margin--desktop;
    }
  }

  &.page__section_scroll_horizontal {
    .page__section-content {
      overflow: hidden;

      @media (min-width: $desktop) {
        overflow: visible;
      }
    }

    .page__section-title {
      margin-right: $page-outside-margin--smartphone;

      @media (min-width: $desktop) {
        margin-right: 0;
      }
    }
  }
}
