@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: page;

.page_type_inner {
  .page__body {
    padding-top: 28px;
    padding-bottom: 48px;

    @media (min-width: $desktop) {
      padding-top: 56px;
      padding-bottom: 112px;
    }
  }

  .page__title {
    margin-bottom: 20px;

    @media (min-width: $desktop) {
      margin-bottom: 32px;
    }
  }
}
