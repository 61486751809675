@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: page;

.page__section_theme_compact {
  .page__section-title {
    margin-bottom: 12px;
    letter-spacing: .5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 22px;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
