@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: dialog-window;

.dialog-window_type_offer {
  &.dialog-window_theme_standard {
    max-width: none;
    padding: 0;

    @media (min-width: $tablet-portrait) {
      width: 100vw;
      max-width: 910px;
    }

    .dialog-window__body {
      min-height: 100vh;

      @media (min-width: $tablet-portrait) {
        min-height: 100px;
      }
    }

    .dialog-window__close {
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .5);
      background-image: url('dialog-window_type_offer--dialog-window__close.svg');
      background-size: 24px;

      &:hover, &:focus {
        background-color: $white;
      }

      @media (min-width: $tablet-portrait) {
        top: 16px;
        right: 16px;
        width: 32px;
        height: 32px;
      }
    }
  }
}
