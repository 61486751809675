@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: promo-banner;

@import '../../promo-banner-background-image.mixin';

.promo-banner_offer_zg {
  &.promo-banner_type_landing {
    .promo-banner__background {
      &::before {
        @include promo-banner-background-image('zg');

        box-sizing: border-box;
        padding-bottom: 240px;

        @media (min-width: $tablet-portrait) {
          padding-bottom: 0;
        }

        @media (min-width: $tablet-portrait) {
          padding-bottom: 0;
          background-position: bottom right;
          background-size: contain;
        }
      }
    }

    .promo-banner__highlighted-note {
      position: absolute;
      right: 36px;
      margin-top: 240px;

      &::first-line {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;

        @media (min-width: $tablet-landscape) {
          font-size: 38px;
          line-height: 42px;
        }
      }

      @media (min-width: $tablet-portrait) {
        right: 360px;
        width: 128px;
        margin-top: 72px;
        font-size: 20px;
        line-height: 24px;
      }

      @media (min-width: $tablet-landscape) {
        right: 380px;
        width: 144px;
      }

      @media (min-width: $desktop) {
        right: 500px;
      }
    }
  }
}
